/* donate.css */
.header1{
    text-align: center !important;
    font-size: 4em !important;
    color: #A27707 !important;
    margin-bottom: 5px !important;
}
.donate {
    font-family: Arial, sans-serif;
    color: #333;
    text-align: center;
    padding: 20px;
}

.donate h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.donate p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.donate-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.donate-info {
    margin-top: 20px;
}

.donate-info h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.donate-info ul {
    list-style-type: none;
    padding: 0;
}

.donate-info li {
    font-size: 1.1em;
    margin-bottom: 10px;
}

.donate-info a {
    color: #007BFF;
    text-decoration: none;
}

.donate-info a:hover {
    text-decoration: underline;
}
