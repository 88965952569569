.main-content {
    margin-top: 14vh; /* Adjust this value to match the height of your navbar */
    padding: 0; /* Remove padding */
}

.header {
    font-size: 7vh; /* Font size for the header */
    font-weight: bold; /* Make the header bold */
    text-align: center; /* Center the header */
    margin-bottom: 2vh; /* Add some space below the header */
    color: #A27707 !important;

}

.event-section {
    display: flex;
    flex-direction: column;
    gap: 40px; /* Increase gap between events */
    padding: 40px; /* Increase padding around the section */
}

.event-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.event-link:hover,
.event-link:focus {
    text-decoration: none;
    color: inherit;
}

.event-link .event-title {
    color: #A27707;
    transition: color 0.3s ease;
}

.event-link:hover .event-title {
    color: #c49209; /* Slightly lighter shade for hover effect */
}

.event-link .event {
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.event-link:hover .event {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
}

.event {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff; /* Add background color for better contrast */
    cursor: pointer; /* Change cursor to pointer */
}

.event-content {
    display: flex;
    flex-direction: row;
    padding: 20px; /* Decrease padding for better spacing */
    gap: 20px; /* Add gap between image and details */
    align-items: center; /* Center align items vertically */
}

.event img {
    width: 400px; /* Adjust width */
    height: 500px; /* Adjust height to make image taller */
    object-fit: cover; /* Ensure the image covers the area */
    border-radius: 8px; /* Add border radius for rounded corners */
}

.event-details {
    flex: 1; /* Allow details to take up remaining space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.event-category {
    color: #999;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px; /* Add margin for spacing */
}

.event-title {
    font-size: 24px;
    margin: 10px 0;
}

.event-date {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px; /* Add margin for spacing */
}

.event-description {
    font-size: 16px;
    color: #333;
    white-space: pre-line; /* Ensure new lines are preserved in the description */
    line-height: 1.5; /* Improve line spacing for readability */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .main-content {
        padding: 0; /* Remove padding */
    }

    .event-section {
        padding: 0; /* Remove padding */
        gap: 20px; /* Adjust gap between events */
    }

    .event {
        width: 100%; /* Make the event card full width */
        margin: 0; /* Remove margin */
    }

    .event-content {
        flex-direction: column; /* Stack image and details vertically */
        align-items: center; /* Center align items */
        text-align: center; /* Center align text */
        padding: 10px; /* Adjust padding */
    }

    .event img {
        width: 100%; /* Make image full width */
        height: 250px; /* Adjust height to fit content */
        margin-bottom: 15px; /* Add bottom margin */
    }

    .event-details {
        padding: 0 10px; /* Adjust padding */
    }

    .event-category {
        font-size: 10px; /* Reduce font size for category */
    }

    .event-title {
        font-size: 16px; /* Reduce font size for title */
    }

    .event-date {
        font-size: 12px; /* Reduce font size for date */
    }

    .event-description {
        font-size: 12px; /* Reduce font size for description */
        text-align: left; /* Align text to the left */
        line-height: 1.4; /* Reduce line height */
    }

    .event-link .event {
        box-shadow: none;
        transform: none;
    }

    .event-link:hover .event {
        box-shadow: none;
        transform: none;
    }
}