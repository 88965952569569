/* GetInvolvedPage.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 2vh;
    background-color: #fff;
    font-family: 'PT Sans', sans-serif;
  }
  
  .heading {
    font-size: 4vw;
    font-weight: 900;
    margin-bottom: 1vh;
    margin-top: 0vh;
    text-align: center;
    color: #A27707 !important;
  }
  
  .content {
    max-width: 70vw;
    margin: 0 auto;
    text-align: center;
    font-size: 2vw;
    line-height: 1.6;
  }
  
  .text {
    margin-bottom: 2vh;
  }
  
  .button {
    display: inline-block;
    padding: 1.5vh 3vh;
    font-size: 1.5vw;
    color: #fff;
    background-color: #A27707;
    border-radius: 0.4vh;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin-top: 2vh;
  }
  
  .buttonHover {
    background-color: #8a5e06;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 6vh 4vh;
    }
  
    .heading {
      font-size: 12vw;
      margin-bottom: 4vh;
    }
  
    .content {
      max-width: 90vw;
      font-size: 8vw;
    }
  
    .text {
      font-size: 4vw;
      line-height: 2;
      margin-bottom: 4vh;
    }
  
    .button {
      padding: 3vh 3vh;
      font-size: 3vw;
      margin-top: 5vh;
    }
  }
  