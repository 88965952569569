/* Updated hero CSS */
img.hero {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  text-align: center; /* Center text within the container */
}

.hero-container > h1 {
  color: #fff;
  font-size: 7vw; /* Slightly smaller font size */
  margin-top: -5vh; /* Adjust margin for better positioning */
}

.hero-btns {
  margin-top: 2vh; /* Responsive margin */
}

.hero-btns .btn {
  margin: 6px;
  font-size: 1.8rem;
  background-color: #fff; /* White background color */
  color: #000; /* Black text color */
  border: none; /* Remove border */
  padding: 12px 24px; /* Adjust padding */
  border-radius: 4px; /* Add border radius */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.hero-btns .btn:hover {
  background-color: #ddd; /* Slightly darker background color on hover */
}

/* Media query for larger screens */
@media (min-width: 769px) {
  .hero-container {
    margin-top: 5vh; /* Add margin-top for regular view */
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .hero-container > h1 {
    font-size: 10vw; /* Increase font size for mobile view */
    margin-top: -13.5vh;
  }
}