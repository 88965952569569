.our-team {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.header {
    padding: 20px;
    text-align: center;
}

.header h1 {
    text-align: center !important;
    font-size: 1.4em !important;
    color: #A27707 !important;
    margin-bottom: 5px !important;
}

.team-content {
    padding: 10px; /* Reduced padding */
}

.team-content h2 {
    text-align: center;
    margin-top: 10px; /* Reduced margin */
    color: #333;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.team-member {
    width: 30%;
    margin: 5px 0; /* Reduced margin */
    padding: 0 5px; /* Reduced padding */
    box-sizing: border-box;
    text-align: center;
}

.team-member:last-child {
    margin-left: auto;
    margin-right: auto;
}

.team-member img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.team-member h3 {
    margin: 5px 0 3px 0; /* Reduced margin */
    color: #A27707;
}

.team-member h4 {
    margin: 3px 0; /* Reduced margin */
    font-weight: normal;
    color: #777;
}

.team-member p {
    font-size: 0.9em;
    color: #666;
    text-align: justify;
    line-height: 1.6;
    word-break: break-word;
    hyphens: auto;
    margin: 5px 0; /* Reduced margin */
    word-spacing: 0.1em;
}

.main-content {
    margin-top: 14vh;
}

@media (max-width: 768px) {
    .team-members {
        flex-direction: column;
        align-items: center;
    }

    .team-member {
        width: 100%;
        margin: 10px 0; /* Reduced margin */
    }

    .team-member:last-child {
        margin-left: 0;
        margin-right: 0;
    }

    .team-member img {
        width: 150px;
        height: 150px;
    }

    .team-member p {
        text-align: justify;
        hyphens: auto;
        word-spacing: 0.1em;
    }
}
