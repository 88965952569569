.our-mission {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9; /* Add a light background color */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.our-mission-header {
  background-color: #292726; /* Change background color */
  padding: 20px;
  text-align: center;
  border-bottom: 2px solid #ddd;
  border-radius: 10px 10px 0 0; /* Round top corners */
}

.our-mission-header h1 {
  margin: 0;
  color: #fff; /* Change text color */
  font-size: 2.5em; /* Increase font size */
}

.our-mission-content h2 {
  margin-top: 20px;
  color: #A27707; /* Change text color */
  font-size: 1.8em; /* Increase font size */
}

.our-mission-content p {
  font-size: 1.1em;
  line-height: 1.6em;
  color: #666;
  margin-bottom: 20px; /* Add space below paragraphs */
}

.our-mission-content ul {
  list-style-type: none;
  padding: 0;
}

.our-mission-content ul li {
  background: #f8f8f8;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border-left: 5px solid #A27707; /* Add a left border */
}

.our-mission-content ul li strong {
  color: #333;
}

.our-mission-title {
  text-align: center;
  font-size: 3em;
  color: #A27707;
  margin-bottom: 20px;
}

.our-mission-main-content {
  margin-top: 120px; /* Apply margin to the main content */
}
.our-mission-title{
    font-size: 5vw;
    font-weight: bolder;
}
@media screen and (max-width: 960px) {
    .our-mission-main-content {
        margin-top: 90px; /* Apply margin to the main content */
      }
      .our-mission-title{
        font-size: 12vw;
        font-weight: bolder;
    }
}
