.footer-container-custom {
    background-color: #A27707;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social-media-custom {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: -50px; /* Add margin to create space */
}

.social-icon-link-custom {
    color: #fff;
    font-size: 32px; /* Increase the size of the social icons */
    z-index: 1; /* Ensure links are clickable */
}

.social-logo-custom {
    color: #fff;
    margin-top: 16px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    z-index: 1; /* Ensure logo is clickable */
}

.logo-custom {
    width: 500px !important; /* Default logo size */
}

@media screen and (max-width: 820px) {
    .social-media-wrap-custom {
        flex-direction: column;
    }

    .social-icons-custom {
        flex-direction: row; /* Ensure icons stay in a row on smaller screens */
        margin-bottom: -40px; /* Adjust spacing for better appearance */
    }

    .social-logo-custom {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
    }
}

@media screen and (max-width: 880px) {
    .logo-custom {
        width: 300px !important; /* Increase logo size on mobile */
    }

    .footer-container-custom {
        padding: 2rem 0 1rem 0; /* Adjust padding for smaller screens */
    }

    .social-icons-custom {
        gap: 5px; /* Decrease gap between icons for smaller screens */
    }

    .social-icon-link-custom {
        font-size: 28px; /* Adjust icon size for smaller screens */
    }
}