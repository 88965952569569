/* General styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.App {
  text-align: center;
}

.home,
.about,
.contact,
.donate,
.initiatives,
.involved,
.signup {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
  color: #000; /* Set text color to black */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Contact form styles */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  width: 100%;
  min-height: 100vh;
  padding: 2vh;
  background-color: #fff;
}

.contact-heading {
  text-align: center !important;
  font-size: 5em !important;
  color: #A27707 !important;
  margin-bottom: 5px !important;
  margin-top: 2vh !important; /* Adjust margin-top to move it higher */
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 70vw; /* Responsive max-width */
  margin: 0 auto;
  padding: 4vh; /* Responsive padding */
  background-color: #f9f9f9; /* Light background color */
  border-radius: 0.8vh;
  box-shadow: 0 0.2vh 0.4vh rgba(0, 0, 0, 0.1); /* Light shadow */
}

.contact-form form div {
  display: flex;
  flex-direction: column; /* Stack inputs vertically on mobile */
  gap: 2vh; /* Responsive gap */
}

.contact-form input,
.contact-form textarea,
.contact-form button,
.contact-form .react-datepicker-wrapper,
.contact-form .react-datepicker__input-container,
.contact-form .datepicker {
  width: 100%;
  padding: 2vh; /* Responsive padding */
  margin: 1vh 0;
  border: 0.1vh solid #ccc; /* Light border */
  border-radius: 0.4vh;
  font-size: 1.2vw; /* Responsive font size */
}

.contact-form input {
  flex: 1; /* Ensure inputs take equal space */
}

.contact-form input[type="email"] {
  font-size: 1.4vw; /* Responsive font size for email input */
  padding: 2vh; /* Increase padding for better spacing */
  margin: 1vh 0; /* Increase margin for better spacing */
  border: 0.1vh solid #ccc; /* Light border */
  border-radius: 0.4vh; /* Add border radius */
  font-size: 1.2vw; /* Responsive font size */
}

.contact-form input[type="email"]::placeholder {
  color: #888; /* Light gray color for placeholder text */
  font-style: italic; /* Italic style for placeholder text */
}

.contact-form textarea {
  height: 20vh; /* Responsive height */
}

.contact-form button {
  background-color: #A27707; /* Gold button color */
  color: white;
  border: none;
  cursor: pointer;
  padding: 1.5vh 3vh; /* Adjust padding for better button size */
  font-size: 1.5vw; /* Adjust font size for better readability */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.contact-form button:hover {
  background-color: #8a5e06; /* Darker shade for hover */
}

.contact-info {
  margin-top: 2vh;
  font-size: 2.1vw; /* Responsive font size */
}

.contact-info p {
  margin: 0.6vh 0;
}

.contact-info a {
  color: #000;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
.main-content {
  margin-top: 14vh; /* Adjust this value to match the height of your navbar */
}


/* Media queries for mobile view */
@media (max-width: 768px) {
  .contact-heading {
    font-size: 5vw; /* Adjust font size for smaller screens */
  }

  .contact-form form {
    max-width: 90vw; /* Increase max-width for smaller screens */
    padding: 2vh; /* Adjust padding for smaller screens */
  }

  .contact-form form div {
    flex-direction: column; /* Stack inputs vertically on mobile */
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form button,
  .contact-form .react-datepicker-wrapper,
  .contact-form .react-datepicker__input-container,
  .contact-form .datepicker {
    font-size: 3vw; /* Adjust font size for smaller screens */
    padding: 1.5vh; /* Adjust padding for smaller screens */
  }

  .contact-form input[type="email"] {
    font-size: 3vw; /* Adjust font size for smaller screens */
    padding: 2vh; /* Increase padding for better spacing */
    margin: 1vh 0; /* Increase margin for better spacing */
    border: 0.1vh solid #ccc; /* Light border */
    border-radius: 0.4vh; /* Add border radius */
  }

  .contact-form textarea {
    height: 15vh; /* Adjust height for smaller screens */
  }

  .contact-form button {
    font-size: 4vw; /* Adjust font size for better readability */
    padding: 2vh 4vh; /* Adjust padding for better button size */
  }

  .contact-info {
    font-size: 3vw; /* Adjust font size for smaller screens */
  }
}