.logo {
  width: 20vw !important;
  height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.navbar {
  background: #A27707 !important;
  height: 14vh !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-size: 2vw !important;
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 999 !important;
  padding: 0 4vw !important;
}

.navbar-container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 100% !important;
  max-width: 1500px !important;
  width: 100% !important;
}

.navbar-logo {
  color: #fff !important;
  cursor: pointer !important;
  text-decoration: none !important;
  font-size: 2.5vw !important;
  display: flex !important;
  align-items: center !important;
  white-space: nowrap !important;
}

.nav-menu {
  display: flex !important;
  list-style: none !important;
  text-align: center !important;
  font-size: 1.5vw !important;
  white-space: nowrap !important;
  margin-left: 2vw !important;
  margin-top: 0.8vw !important;
}

.nav-item {
  height: 100% !important;
  position: relative;
}

.nav-links {
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  text-decoration: none !important;
  padding: 1.5vw 2vw !important;
  height: 100% !important;
  white-space: nowrap !important;
  margin-top: 0.5vw !important;
}

.nav-links:hover {
  border-bottom: 0.4vw solid #fff !important;
  transition: all 0.2s ease-out !important;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #242222;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 100%; /* Position directly below the parent */
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Match the width of the parent link */
}

.dropdown-menu li {
  width: 100%;
}

.dropdown-link {
  color: #A27707 !important; /* Updated to A27707 */
  background-color: transparent !important; /* No background color */
  padding: 12px 16px !important;
  text-decoration: none !important;
  display: block !important;
  transition: none !important; /* No transition effects */
}

.dropdown-link:hover {
  background-color: transparent !important; /* No hover background color */
  color: #A27707 !important; /* Maintain A27707 font color on hover */
}

.nav-item:hover .dropdown-menu,
.nav-item.active .dropdown-menu {
  display: block !important;
}

.fa-bars, .fa-times {
  color: #fff !important;
  font-size: 4vw !important;
}

.menu-icon {
  display: none !important;
}

.signup-button {
  color: #fff !important;
  background-color: #007BFF !important;
  padding: 1vw 2vw !important;
  border: none !important;
  cursor: pointer !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  font-size: 1.5vw !important;
  max-width: 10vw !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
}

.signup-button:hover,
.signup-button:focus,
.signup-button:active {
  text-decoration: none !important;
  background-color: #0056b3 !important;
  transition: all 0.3s ease !important;
}

.signup-button a {
  text-decoration: none !important;
}

@media screen and (max-width: 960px) {
  .navbar-container {
    justify-content: space-between !important;
    padding: 0 4vw !important;
  }

  .nav-menu {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    height: calc(100vh - 12vh) !important;
    position: absolute !important;
    top: 12vh !important;
    left: -100% !important;  /* Initially hidden */
    opacity: 0 !important;   /* Initially hidden */
    transition: all 0.5s ease !important;
    z-index: 2 !important;
  }

  .nav-menu.active {
    background: #242222 !important;
    left: 0 !important;    /* Shown when active */
    opacity: 1 !important; /* Shown when active */
    transition: all 0.5s ease !important;
  }

  .nav-links {
    text-align: center !important;
    padding: 2rem !important;
    width: 100% !important;
    display: table !important;
    font-size: 5vw !important;
    margin-top: 1vw !important;
  }

  .nav-links:hover {
    background-color: #fff !important;
    color: #242424 !important;
    border-radius: 0 !important;
  }

  .navbar-logo {
    position: relative !important;
    transform: none !important;
    font-size: 6vw !important;
  }

  .menu-icon {
    display: block !important;
    position: absolute !important;
    top: 50% !important;
    right: 5% !important;
    transform: translateY(-50%) !important;
    font-size: 7vw !important;
    cursor: pointer !important;
  }

  .signup-button {
    display: none !important;
  }

  .nav-item .dropdown-menu {
    display: none !important;
    position: absolute !important;
    background-color: #242222 !important;
    box-shadow: none !important;
    width: 100% !important; /* Match the width of the parent link */
    padding: 0 !important;
    top: 100% !important; /* Position directly below the parent */
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .nav-item:hover .dropdown-menu,
  .nav-item.active .dropdown-menu {
    display: block !important;
  }

  .nav-item .dropdown-menu li {
    width: 100% !important;
  }

  .nav-item .dropdown-menu .dropdown-link {
    color: #fff !important;
    padding: 1rem 2rem !important;
    text-align: center !important;
    display: block !important;
  }

  .nav-item .dropdown-menu .dropdown-link:hover {
    background-color: #555 !important;
  }

  .nav-item.active .dropdown-menu {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    width: 30vw !important;
  }

  .navbar {
    height: 10vh !important;
    padding: 0 3vw !important;
  }

  .nav-links {
    padding: 2rem !important;
    font-size: 5vw !important;
    margin-top: 1vw !important;
  }

  .nav-menu {
    top: 10vh !important;
    height: calc(100vh - 10vh) !important;
  }

  .dropdown-menu {
    position: absolute !important; /* Ensure absolute positioning */
    background-color: #242222 !important;
    box-shadow: none !important;
    width: 100% !important; /* Match the width of the parent link */
    padding: 0 !important;
    top: 100% !important; /* Position directly below the parent */
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .dropdown-menu li {
    width: 100% !important;
  }

  .dropdown-link {
    color: #fff !important;
    padding: 1rem 2rem !important;
    text-align: center !important;
    display: block !important;
  }

  .dropdown-link:hover {
    background-color: #555 !important;
  }

  .nav-item:hover .dropdown-menu,
  .nav-item.active .dropdown-menu {
    display: block !important;
  }
}